import { isArray } from 'underscore';

export const hasErrors = (error) => {
  return (error !== null && error !== undefined);
}

export const printErrors = (error) => {
  if (isArray(error)) {
    return error.filter(e => e).join('. ');
  }
  return error;
}
