import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './components/async.component';
import Paymachine from './components/layouts/paymachine/layout-paymachine.component';
// TRY to authenticate
import TryToAuthenticate from './components/TryToAuthenticate/container';
import r from './config/routes';
import NoLayout from './layouts/layout-none/layout-none.component';


// MAIN APP NAVS
const AsyncHomePage = asyncComponent(() => import('./components/pages/Home/container'));
const AsyncLoginPage = asyncComponent(() => import('./components/pages/Login/component'));

const AsyncRegisterPage = asyncComponent(() => import('./components/pages/Register/component'));
const AsyncRegisterConfirmPage = asyncComponent(() => import('./components/pages/RegisterConfirm/component'));

const AsyncForgotPasswordPage = asyncComponent(() => import('./components/pages/ForgotPassword/component'));
const AsyncForgotPasswordConfirmPage =
  asyncComponent(() => import('./components/pages/ForgotPasswordConfirm/component'));

const AsyncEditProfilePage = asyncComponent(() => import('./components/pages/EditProfile/container'));

const AsyncRequestPayPage = asyncComponent(() => import('./components/pages/RequestPay/RequestPay.container'));
const AsyncInstantPayListPage = asyncComponent(() => import('./components/pages/PaymentRequestsList/container'));

const AsyncRequestLoanPage = asyncComponent(() => import('./components/pages/RequestLoan/container'));
const AsyncLoansListPage = asyncComponent(() => import('./components/pages/LoansList/container'));

const AsyncSettingsNotificationsPage
  = asyncComponent(() => import('./components/pages/SettingsNotifications/container'));

const AsyncNotFound = asyncComponent(() => import('./containers/not-found/not-found.component'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const PaymachineLayout = props => (
  <Paymachine>{props.children}</Paymachine>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout }) => {
  let activeLayout;
  switch (layout.currentLayout) {
    case 'paymachine':
      activeLayout = PaymachineLayout;
      break;
    default:
      activeLayout = PaymachineLayout;
  }

  return (
    <TryToAuthenticate>
      <Switch>
        <AppRoute path={r.Home} exact component={AsyncHomePage} props={childProps} layout={activeLayout} />
        <AppRoute path={r.Login} exact component={AsyncLoginPage} props={childProps} layout={NoLayout} />
        <AppRoute path={r.Register} exact component={AsyncRegisterPage} props={childProps} layout={NoLayout} />
        <AppRoute path={r.RegisterConfirm} exact component={AsyncRegisterConfirmPage} props={childProps} layout={NoLayout} />
        <AppRoute path={r.ForgotPassword} exact component={AsyncForgotPasswordPage} props={childProps} layout={NoLayout} />
        <AppRoute path={r.ForgotPasswordConfirm} exact component={AsyncForgotPasswordConfirmPage} props={childProps} layout={NoLayout} />

        <AppRoute path={r.EditProfile} exact component={AsyncEditProfilePage} props={childProps} layout={activeLayout} />

        <AppRoute path={r.RequestPay} exact component={AsyncRequestPayPage} props={childProps} layout={activeLayout} />
        <AppRoute path={r.RequestPayList} exact component={AsyncInstantPayListPage} props={childProps} layout={activeLayout} />

        <AppRoute path={r.RequestLoan} exact component={AsyncRequestLoanPage} props={childProps} layout={activeLayout} />
        <AppRoute path={r.LoansList} exact component={AsyncLoansListPage} props={childProps} layout={activeLayout} />

        <AppRoute path={r.SettingsNotifications} exact component={AsyncSettingsNotificationsPage} props={childProps} layout={activeLayout} />

        <AppRoute component={AsyncNotFound} layout={activeLayout} />
      </Switch>
    </TryToAuthenticate>
  );
};
