// export default {
//   50: "#e1f5fe",
//   100: "#b3e5fc",
//   200: "#81d4fa",
//   300: "#068ed9",
//   400: "#29b6f6",
//   500: "#f3f6fe",
//   600: "#039be5",
//   700: "#0288d1",
//   800: "#0277bd",
//   900: "#01579b",
//   A100: "#80d8ff",
//   A200: "#40c4ff",
//   A400: "#f3f6fe",
//   A700: "#0091ea",
//   MainBackground: '#f3f6fe',
// }
//
// // BG: f3f6fe
// // MAIN: 068ed9

// Indygo
// export default {
//   50: '#E8EAF6',
//   100: '#C5CAE9',
//   200: '#9FA8DA',
//   300: '#7986CB',
//   400: '#5C6BC0',
//   500: '#3F51B5', //500: '#00ced0',
//   600: '#3949AB',
//   700: '#303F9F',
//   800: '#283593',
//   900: '#1A237E',
//   A100: '#8C9EFF',
//   A200: '#536DFE',
//   A400: '#3D5AFE',
//   A700: '#304FFE',
//   Main: {
//     background: '#f0f4fc',
//   },
//   SideNav: {
//     background: '#21293e',
//   },
// }



// Navy Blue
export default {
  50: '#E3F2FD',
  100: '#BBDEFB',
  200: '#90CAF9',
  // 300: '#1A237E',
  300: '#21293e',
  400: '#42A5F5',
  // 500: '#2196F3',
  500: '#058bd4', //XXX: The one provided in asana
  // 500: '#008dd2', //XXX: The one used previously
  600: '#1E88E5',
  // 700: '#1976D2',
  // 700: '#008dd2', //XXX:
  700: '#058bd4',
  800: '#1565C0',
  900: '#0D47A1',
  A100: '#FF80AB',
  A200: '#FF4081',
  // A400: '#F50057',
  // A400: '#008dd2', //XXX:
  A400: '#058bd4',
  A700: '#C51162',
  Main: {
    // background: '#f0f4fc',
    // background: '#008dd2', //XXX:
    background: '#058bd4',
  },
  SideNav: {
    background: '#21293e',
  },
}

// export default {
//   50: '#FAFAFA',
//   100: '#F5F5F5',
//   200: '#EEEEEE',
//   300: '#E0E0E0',
//   400: '#BDBDBD',
//   500: '#9E9E9E',
//   600: '#757575',
//   700: '#616161',
//   800: '#424242',
//   900: '#212121',
//   A100: '#FF80AB',
//   A200: '#FF4081',
//   A400: '#F50057',
//   A700: '#C51162',
// }


// export default {
//   50: "#F1F8E9",
//   100: "#DCEDC8",
//   200: "#C5E1A5",
//   300: "#AED581",
//   400: "#9CCC65",
//   500: "#8BC34A",
//   600: "#7CB342",
//   700: "#689F38",
//   800: "#558B2F",
//   900: "#33691E",
//   A100: "#CCFF90",
//   A200: "#B2FF59",
//   A400: "#76FF03",
//   A700: "#64DD17",
//   // MainBackground: '#f3f6fe',
// }
