import * as types from './types';
// import _ from 'underscore';

const initial = {
  list: [],
  loading: false,
  message: null // {msg: 'User authenticated', type: 'info'}
};

const paymentRequests = (state = initial, action) => {
  switch (action.type) {
    case types.GET_PAYMENT_REQUESTS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.GET_PAYMENT_REQUESTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: (action.payload.data === null) ? [] : action.payload.data,
      };
    case types.CANCEL_PAYMENT_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CANCEL_PAYMENT_REQUESTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelConfirmation: action.payload
      };
    case types.CANCEL_PAYMENT_REQUESTS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default paymentRequests;
