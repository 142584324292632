/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getPaymentRequests = (clientId, userId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/request-pay-list`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const cancelPaymentRequests = (clientId, userId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.CANCEL_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CANCEL_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CANCEL_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/cancel-payment-requests`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

