import * as types from './types';
import _ from 'underscore';

const initial = {
  clientId: null,
  simulations: [],
  simulating: false,
  simulationFailure: false,
  addedNew: false,
  newPaymentRequest: {
    success: false,
    loading: false,
    error: null
  },
  traditionalLoan: {
    credit_limit: 0,
    interest_rate: 0.0,
    repayment_day_options: [],
    repayment_options: []
  },
  user: null,
  paymentSchedule: 'monthly',
  userSettings: {
    loan_type_loan: {
      enabled: false
    },
    loan_type_instantpay: {
      enabled: false
    }
  },
  fees: {
    ach_next_day: {
      enabled: false
    },
    ach_same_day: {
      enabled: false
    },
    cheque: {
      enabled: false
    }
  },
  requestPay: {
    fee: 0,
    balance_available: 0
  },
  list: [],
  message: null,
  loading: false
};

const clientsUsers = (state = initial, action) => {
  switch (action.type) {
    case types.RESET_CLIENTS_USER_REQUEST:
      return {
        ...state,
        newPaymentRequest: {
          loading: false,
          error: null,
          success: false
        }
      };
    case types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST:
      return {
        ...state,
        newPaymentRequest: {
          ...state.newPaymentRequest,
          loading: true,
          error: null,
          success: false
        }
      };
    case types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS:
      return {
        ...state,
        newPaymentRequest: {
          ...state.newPaymentRequest,
          loading: false,
          error: null,
          success: true
        }
      };
    case types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE:
      return {
        ...state,
        newPaymentRequest: {
          ...state.newPaymentRequest,
          loading: false,
          error: action.payload.message,
          success: false
        }
      };
    case types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS:
      return {
        ...state,
        requestPay: action.payload.data,
        loading: false
      };
    case types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_CLIENTS_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_CLIENTS_USERS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.SIMULATE_INSTANT_PAYROLL_REQUEST:
      return {
        ...state,
        loading: false,
        simulating: true,
        simulationFailure: false,
        message: null
      };
    case types.SIMULATE_INSTANT_PAYROLL_REQUEST_SUCCESS:
      let { simulations } = state;
      simulations.push({
        simid: action.payload.data.requested_amount + '-' + action.payload.data.fees.processing.value,
        data: action.payload.data
      });

      return {
        ...state,
        loading: false,
        simulating: false,
        simulations: simulations,  
        simulationFailure: false,
        message: null
      };
    case types.SIMULATE_INSTANT_PAYROLL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        simulating: false,
        simulationFailure: true,
        message: action.payload.message
      };
    case types.GET_CLIENTS_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        addedNew: false,
        list: (_.isArray(action.payload.data) ? action.payload.data : []),
        loading: false
      };
    case types.GET_CLIENTS_USER_REQUEST:
      return { ...state, loading: true };
    case types.GET_CLIENTS_USER_REQUEST_SUCCESS:
      const newUser = action.payload.data;
      return {
        ...state,
        loading: false,
        addedNew: false,
        user: newUser,
        fees: newUser.fees,
        userSettings: newUser.settings,
        paymentSchedule: newUser.payment_schedule,
        notifications_settings: newUser.notifications_settings || [],
        traditionalLoan: {
          ...newUser.traditional_loan,
          repayment_options: newUser.traditional_loan.repayment_options || [],
          repayment_day_options: newUser.traditional_loan.repayment_day_options || []
        }
      };
    case types.CREATE_CLIENTS_USERS_REQUEST:
      return {
        ...state,
        clientId: action.payload,
        loading: true
      };
    case types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        addedNew: true,
        loading: false
      };
    case types.CREATE_CLIENTS_USERS_REQUEST_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        loading: false
      };
    case types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        loading: false
      };
    default:
      return state;
  }
};

export default clientsUsers;
