import { put, takeEvery, select } from "redux-saga/effects";
// import { push } from 'connected-react-router';
import * as types from "./types";

// import r from '../../config/routes';
import { getPaymentRequests } from "./actions";
import { showConfirmationInfo } from "../app-state/actions";

const getCancelResult = state => state.paymentRequests.cancelConfirmation;
function* afterStatusUpdate() {
  yield put(
    getPaymentRequests(
      localStorage.getItem("AUTH_CLIENT_ID"),
      localStorage.getItem("AUTH_ID")
    )
  );
  const confirmation = yield select(getCancelResult);
  if (!confirmation.data) {
    yield put(showConfirmationInfo("Success!"));
  } else {
    if (confirmation.data.length > 0) {
      yield put(showConfirmationInfo(confirmation.data[0]));
    } else {
      yield put(
        showConfirmationInfo("You can only cancel PENDING transactions")
      );
    }
  }
}

export default function* paymentRequests() {
  yield takeEvery(
    types.CANCEL_PAYMENT_REQUESTS_REQUEST_SUCCESS,
    afterStatusUpdate
  );
  yield takeEvery(
    types.CANCEL_PAYMENT_REQUESTS_REQUEST_FAILURE,
    afterStatusUpdate
  );
}
