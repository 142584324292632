import { API_URL } from './env';

export default {
  Home: '/',
  Dashboard: '/',
  Login: '/login',
  Register: '/register',
  RegisterConfirm: '/register-confirm/:email/:code',
  ForgotPassword: '/forgot-password',
  ForgotPasswordConfirm: '/forgot-password-confirm/:email/:code',

  RequestPay: '/request-pay',
  RequestPayList: '/instant-pay-status',
  EditProfile: '/edit-profile',

  LoansList: '/loans',
  LoansListCSV: `${API_URL}/clients-users/:client_id/users/:client_user_id/traditional-loans.csv?_pmatoken=:auth_token`,
  RequestLoan: '/request-loan',

  SettingsNotifications: '/settings/notifications',

  APIPaymentRequestsListCSV: `${API_URL}/clients-users/:client_id/users/:client_user_id/request-pay-list.csv?_pmatoken=:auth_token`

  // TermsAndConditionsEdit: '/settings/terms-and-conditions'
};
