import * as types from './types';

const initial = {
  list: [],
  message: null, // {msg: 'User authenticated', type: 'info'}
  loading: false,
  newLoan: {
    loading: false,
    error: null
  }
};

const loans = (state = initial, action) => {
  switch (action.type) {
    case types.RESET_LOAN_REQUEST:
      return {
        ...state,
        newLoan: {
          loading: false,
          error: null,
          success: false
        }
      };
    case types.CREATE_LOAN_REQUEST:
      return {
        ...state,
        newLoan: {
          ...state.newLoan, loading: true, error: null, success: false
        }
      };
    case types.CREATE_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        newLoan: {
          ...state.newLoan, loading: false, error: null, success: true
        }
      };
    case types.CREATE_LOAN_REQUEST_FAILURE:
      return {
        ...state,
        newLoan: {
          ...state.newLoan, loading: false, error: action.payload.message, success: false
        }
      };
    case types.GET_LOANS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_LOANS_REQUEST_SUCCESS:
      return {
        ...state,
        list: (action.payload.data === null) ? [] : action.payload.data,
        loading: false
      };
    case types.CANCEL_LOANS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CANCEL_LOANS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.CANCEL_LOANS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default loans;
