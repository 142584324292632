/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const createLoan = (clientId, userId, data) => ({

  [RSAA]: {
    types: [
      {
        type: types.CREATE_LOAN_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_LOAN_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_LOAN_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      amount: parseFloat(data.amount),
      repayments: parseInt(data.repayments),
      repayment_day: parseInt(data.repaymentDay),
      fee: data.fee
    })
  }
});

export const simulateLoan = (clientId, userId, data) => ({

  [RSAA]: {
    types: [
      {
        type: types.SIMULATE_LOAN_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.SIMULATE_LOAN_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.SIMULATE_LOAN_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/simulate/loan`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      amount: parseFloat(data.amount),
      repayments: parseInt(data.repayments),
      repayment_day: parseInt(data.repaymentDay),
      fee: data.fee
    })
  }
});

export const getLoans = (clientId, userId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/traditional-loans`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const cancelSelected = (clientId, userId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.CANCEL_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CANCEL_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CANCEL_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/cancel-traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const resetRequest = () => ({
  type: types.RESET_LOAN_REQUEST
});
