import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'underscore';

import r from '../../config/routes';
import Login from '../auth/login/container';
import NoLayout from '../../layouts/layout-none/layout-none.component';


const styles = {
  root: {
    backgroundColor: 'red',
  },
};

const TryToAuthenticate = (props) => {
  useEffect(() => {
    props.tryToAuthenticate();
  }, []);

  const validPaths = [
    r.Login,
    r.Register,
    r.RegisterConfirm,
    r.ForgotPassword,
    r.ForgotPasswordConfirm,
  ];

  // Allow for predefined paths OR authenticated
  if (
    (_.indexOf(validPaths, props.location.pathname) !== -1) ||
    (props.location.pathname.startsWith('/register-confirm/')) ||
    (props.location.pathname.startsWith('/forgot-password-confirm/')) ||
    (props.auth && props.auth.authenticated)
  ) {
    return ({ ...props.children });
  }

  // Disallow by default
  return <NoLayout><Login styles={styles} /></NoLayout>;
}

export default withRouter(props => <TryToAuthenticate {...props} />);
