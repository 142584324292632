export const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST';
export const CREATE_LOAN_REQUEST_SUCCESS = 'CREATE_LOAN_REQUEST_SUCCESS';
export const CREATE_LOAN_REQUEST_FAILURE = 'CREATE_LOAN_REQUEST_FAILURE';

export const RESET_LOAN_REQUEST = 'RESET_LOAN_REQUEST';

export const GET_LOANS_REQUEST = 'GET_LOANS_REQUEST';
export const GET_LOANS_REQUEST_SUCCESS = 'GET_LOANS_REQUEST_SUCCESS';
export const GET_LOANS_REQUEST_FAILURE = 'GET_LOANS_REQUEST_FAILURE';

export const CANCEL_LOANS_REQUEST = 'CANCEL_LOANS_REQUEST';
export const CANCEL_LOANS_REQUEST_SUCCESS = 'CANCEL_LOANS_REQUEST_SUCCESS';
export const CANCEL_LOANS_REQUEST_FAILURE = 'CANCEL_LOANS_REQUEST_FAILURE';

export const SIMULATE_LOAN_REQUEST = 'SIMULATE_LOAN_REQUEST';
export const SIMULATE_LOAN_REQUEST_SUCCESS = 'SIMULATE_LOAN_REQUEST_SUCCESS';
export const SIMULATE_LOAN_REQUEST_FAILURE = 'SIMULATE_LOAN_REQUEST_FAILURE';
