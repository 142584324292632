import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import LogOutIcon from '@material-ui/icons/PowerSettingsNew';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import SettingsIcon from '@material-ui/icons/Settings';

import themes from './themes';
import r from './config/routes';

export const configuredTheme = themes[0].theme;

export const configuredLayout = {
  currentLayout: 'paymachine',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 20
};

export const menuItems = [
  {
    title: 'QUICK ACCESS',
    type: 'header'
  },
  {
    title: 'Dashboard',
    icon: <DashboardIcon style={iconStyle} />,
    href: r.Home
  },
  {
    title: 'Instant Payroll',
    icon: <AccountBalanceIcon style={iconStyle} />,
    href: r.RequestPay,
    enable_for_loan_type: 'instantpay'
  },
  {
    title: 'Instant Payroll Status',
    icon: <img alt='Icon bell' style={{ width: '21px', height: '21px', fill: 'white' }} src="/assets/images/icon_bell.svg" />,
    href: r.RequestPayList,
    enable_for_loan_type: 'instantpay'
  },
  {
    title: 'Loan',
    icon: <img alt='Icon create loan' style={{ width: '22px', height: '20px' }} src="/assets/images/icon_create_loan.png" />,
    href: r.RequestLoan,
    enable_for_loan_type: 'loan'
  },
  {
    title: 'Loan Status',
    icon: <img alt='Icon bell' style={{ width: '21px', height: '21px', fill: 'white' }} src="/assets/images/icon_bell.svg" />,
    href: r.LoansList,
    enable_for_loan_type: 'loan'
  },
  {
    title: 'Settings',
    icon: <SettingsIcon style={iconStyle} />,
    children: [{
      title: 'Edit Profile',
      href: r.EditProfile
    },
    {
      title: 'Notifications',
      href: r.SettingsNotifications
    }]
  },
  {
    title: 'Log out',
    icon: <LogOutIcon style={iconStyle} />,
    href: '#',
    style: {
      // position: 'absolute',
      // bottom: 0,
      // backgroundColor: themes[0].theme.sidenavTheme.palette.background.default
    },
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      localStorage.removeItem('AUTH_ROLE');
      localStorage.removeItem('AUTH_ARN');
      localStorage.removeItem('AUTH_CLIENT_ID');
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('AUTH_USERNAME');
      localStorage.removeItem('AUTH_ID');
      localStorage.removeItem('AUTH_LOGIN_TIME');
      window.location.href = '/';
    }
  },
];
