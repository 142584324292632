import paymachineColors from './components/layouts/colors/paymachine';

const paymachineContentTheme = {
  direction: 'ltr',
  typography: {
    // fontFamily: 'Barlow',
    fontFamily: 'Poppins',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: paymachineColors,
    secondary: paymachineColors,
    background: {
      default: paymachineColors.Main.background,
    }
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#323232',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#323232',
        }
      },
      containedSecondary: {
        backgroundColor: '#dadada',
        color: '#323232',
        '&:hover': {
          backgroundColor: '#dadada',
        }
      },
      textPrimary: {
        color: '#323232',
        '&:hover': {
          backgroundColor: 'none',
        }
      },
      textSecondary: {
        color: '#323232',
        '&:hover': {
          backgroundColor: 'none',
        }
      },
    }
  }
};

const paymachineSidenavTheme = {
  ...paymachineContentTheme,
  palette: {
    ...paymachineContentTheme.palette,
    type: 'dark',
    background: {
      default: paymachineColors.SideNav.background,
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: paymachineColors.SideNav.background,
        color: '#FFF'
      }
    }
  }
};

const themes = [
  {
    id: 'paymachine-default',
    name: 'PayMachine default',
    theme: {
      contentTheme: paymachineContentTheme,
      sidenavTheme: paymachineSidenavTheme
    }
  }];

export default themes;
