/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import _ from 'underscore';
import __ from 'lodash';
import MenuSidenavItem from '../menu-sidenav-item/menu-sidenav-item.component';
import styles from './menu-sidenav.style';

import { menuItems } from '../../../../config';

const MenuSidenav = (props) => {
  const { classes, user, closeSidenav, variant } = props;
  const handleClick = (element, event) => {
    if (!element.children && variant !== 'permanent') {
      closeSidenav();
    }
    if (element.onClick) {
      element.onClick(event);
    }
  };
  const constructNavItems = (itemsArray, cls) => {
    if (_.isEmpty(itemsArray)) {
      return;
    }

    const arr = [];
    itemsArray.forEach((el) => {
      arr.push((
        <MenuSidenavItem
          title={el.title}
          href={el.href}
          key={el.title}
          icon={el.icon}
          type={el.type}
          style={el.style}
          onClick={(event) => handleClick(el, event)}
        >
          {el.children && constructNavItems(el.children, cls)}
        </MenuSidenavItem>));
    });

    return (
      <List
        className={cls.list}
        style={{
          minHeight: '100%',
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {(arr.slice(0, -1))}
        <div style={{ flex: 1 }} />
        {(arr.slice(-1))}
      </List>
    );
  };


  const [items, setItems] = useState(constructNavItems([], classes));

  useEffect(() => {
    props.getClientsUser(localStorage.getItem('AUTH_CLIENT_ID'), localStorage.getItem('AUTH_ID'));
  }, []);

  useEffect(() => {
    const cmi = __.cloneDeep(menuItems); // deep copy

    // Run href manipulations
    const clientID = localStorage.getItem('AUTH_CLIENT_ID');
    const newItems = cmi.map((item, idx) => {
      if (!_.isEmpty(item.href)) {
        item.href = item.href.replace(':client_id', clientID);
      }
      if (!_.isEmpty(item.children)) {
        item.children = item.children.map((child) => {
          if (!_.isEmpty(child.href)) {
            child.href = child.href.replace(':client_id', clientID);
          }

          return child;
        });
      }
      return item;
    });

    // Run settings related manipulations
    const { settings } = props;
    const loan_types = {
      loan: settings.loan_type_loan.enabled,
      instantpay: settings.loan_type_instantpay.enabled
    };

    const newItems2 = newItems.filter((item, idx) => {
      if (!_.isEmpty(item.enable_for_loan_type)) {
        if (loan_types[item.enable_for_loan_type] !== true) {
          return false;
        }
      }

      if (!_.isEmpty(item.children)) {
        item.children = item.children.filter((child) => {
          if (!_.isEmpty(child.enable_for_loan_type)) {
            if (loan_types[child.enable_for_loan_type] !== true) {
              return false;
            }
          }

          return true;
        });
      }
      return true;
    });

    setItems(constructNavItems(newItems2, classes));
  }, [user]);
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.content}>
        {items}
      </div>
    </div>
  );
};

export default withStyles(styles)(MenuSidenav);
