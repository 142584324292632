export const GET_CLIENTS_USERS_REQUEST = 'GET_CLIENTS_USERS_REQUEST';
export const GET_CLIENTS_USERS_REQUEST_SUCCESS = 'GET_CLIENTS_USERS_REQUEST_SUCCESS';
export const GET_CLIENTS_USERS_REQUEST_FAILURE = 'GET_CLIENTS_USERS_REQUEST_FAILURE';

export const RESET_CLIENTS_USER_REQUEST = 'RESET_CLIENTS_USER_REQUEST';

export const GET_CLIENTS_USER_REQUEST = 'GET_CLIENTS_USER_REQUEST';
export const GET_CLIENTS_USER_REQUEST_SUCCESS = 'GET_CLIENTS_USER_REQUEST_SUCCESS';
export const GET_CLIENTS_USER_REQUEST_FAILURE = 'GET_CLIENTS_USER_REQUEST_FAILURE';

export const CREATE_CLIENTS_USERS_REQUEST = 'CREATE_CLIENTS_USERS_REQUEST';
export const CREATE_CLIENTS_USERS_REQUEST_SUCCESS = 'CREATE_CLIENTS_USERS_REQUEST_SUCCESS';
export const CREATE_CLIENTS_USERS_REQUEST_FAILURE = 'CREATE_CLIENTS_USERS_REQUEST_FAILURE';

export const UPDATE_CLIENTS_USERS_REQUEST = 'UPDATE_CLIENTS_USERS_REQUEST';
export const UPDATE_CLIENTS_USERS_REQUEST_SUCCESS = 'UPDATE_CLIENTS_USERS_REQUEST_SUCCESS';
export const UPDATE_CLIENTS_USERS_REQUEST_FAILURE = 'UPDATE_CLIENTS_USERS_REQUEST_FAILURE';

export const CHANGE_PASSWORD_CLIENTS_USERS_REQUEST = 'CHANGE_PASSWORD_CLIENTS_USERS_REQUEST';
export const CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_SUCCESS = 'CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_FAILURE = 'CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_FAILURE';

export const GET_CLIENTS_USER_REQUEST_PAY_REQUEST = 'GET_CLIENTS_USER_REQUEST_PAY_REQUEST';
export const GET_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS = 'GET_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS';
export const GET_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE = 'GET_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE';

export const CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST = 'CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST';
export const CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS = 'CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS';
export const CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE = 'CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE';

export const CREATE_FCM_REGISTRATION_REQUEST = 'CREATE_FCM_REGISTRATION_REQUEST';
export const CREATE_FCM_REGISTRATION_REQUEST_SUCCESS = 'CREATE_FCM_REGISTRATION_REQUEST_SUCCESS';
export const CREATE_FCM_REGISTRATION_REQUEST_FAILURE = 'CREATE_FCM_REGISTRATION_REQUEST_FAILURE';

export const UPDATE_NOTIFICATIONS_SETTINGS_REQUEST = 'UPDATE_NOTIFICATIONS_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_SUCCESS = 'UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_SUCCESS';
export const UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_FAILURE = 'UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_FAILURE';

export const SIMULATE_INSTANT_PAYROLL_REQUEST = 'SIMULATE_INSTANT_PAYROLL_REQUEST';
export const SIMULATE_INSTANT_PAYROLL_REQUEST_SUCCESS = 'SIMULATE_INSTANT_PAYROLL_REQUEST_SUCCESS';
export const SIMULATE_INSTANT_PAYROLL_REQUEST_FAILURE = 'SIMULATE_INSTANT_PAYROLL_REQUEST_FAILURE';
