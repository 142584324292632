import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import auth from '../redux/auth/reducer';
import breadcrumbs from '../redux/breadcrumbs/reducer';
import clientsUsers from '../redux/clients-users/reducer';
import settings from '../redux/settings/reducer';
import paymentRequests from '../redux/payment-requests/reducer';
import loans from '../redux/loans/reducer';
import appState from '../redux/app-state/reducer';
import registerConfirmation from '../redux/register-confirmation/reducer';

import layout from '../redux/theme/layout.reducer';
import theme from '../redux/theme/theme.reducer';


export default history => combineReducers({
  router: connectRouter(history),
  auth,
  breadcrumbs,
  clientsUsers,
  settings,
  paymentRequests,
  loans,
  layout,
  theme,
  appState,
  registerConfirmation
});
