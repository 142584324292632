import { connect } from 'react-redux'
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { toggleSidenav } from '../../../../actions/layout.actions';
import { logout } from '../../../../redux/auth/actions';
import Component from './component';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  layout: {
    sidenavOpen: state.layout.sidenavOpen
  },
  isWidthDown,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  toggleSidenav: () => dispatch(toggleSidenav()),
});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(Component)

export default compose(
  withRouter,
  withWidth(),
  withTheme,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Component);
