export const GET_PAYMENT_REQUESTS_REQUEST = 'GET_PAYMENT_REQUESTS_REQUEST';
export const GET_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'GET_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const GET_PAYMENT_REQUESTS_REQUEST_FAILURE = 'GET_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const APPROVE_PAYMENT_REQUESTS_REQUEST = 'APPROVE_PAYMENT_REQUESTS_REQUEST';
export const APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE = 'APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const DENY_PAYMENT_REQUESTS_REQUEST = 'DENY_PAYMENT_REQUESTS_REQUEST';
export const DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const DENY_PAYMENT_REQUESTS_REQUEST_FAILURE = 'DENY_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const CANCEL_PAYMENT_REQUESTS_REQUEST = 'CANCEL_PAYMENT_REQUESTS_REQUEST';
export const CANCEL_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'CANCEL_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const CANCEL_PAYMENT_REQUESTS_REQUEST_FAILURE = 'CANCEL_PAYMENT_REQUESTS_REQUEST_FAILURE';
