import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createGenerateClassName, jssPreset } from '@material-ui/styles';
import { AppStateProvider, initialState, reducer } from '@paymachine/shared/lib/store/app-state';
import ErrorInfoComponent from '@paymachine/shared/lib/components/ErrorInfo.component';
import ToastComponent from '@paymachine/shared/lib/components/Toast.component';
import { create } from 'jss';
import rtl from 'jss-rtl';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Idle from 'react-idle';
import { JssProvider } from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import _ from 'underscore';
import ConfirmationInfo from './hooks/confirmation-info/container';
import { logout } from './redux/auth/actions';
import Routes from './routes';


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const generateClassName = createGenerateClassName();

const TokenTimeout = (props) => {
  const lt = localStorage.getItem('AUTH_LOGIN_TIME');

  const now = moment();
  const exp = moment(lt).add(55, 'minutes');
  const etm = moment.duration(exp.diff(now));

  useEffect(() => {
    if (!_.isEmpty(etm.asMilliseconds())) {
      setTimeout(() => {
        props.logout();
      }, etm.asMilliseconds());
    }
  }, []);

  if (now >= exp) {
    props.logout();
  }

  return '';
};

const App = (props) => {
  const childProps = {};
  const { themeConfig, layoutConfig } = props;
  const materialTheme = createMuiTheme(themeConfig.contentTheme);

  const [active, setActive] = useState(true);
  const [tout, setTout] = useState(null);

  useEffect(() => {
    if (document.body) {
      document.body.dir = props.themeConfig.contentTheme.direction;
    }

    sessionStorage.setItem(
      'portalData',
      JSON.stringify({
        theme: {
          ...themeConfig
        },
        layout: {
          ...layoutConfig
        }
      })
    );
  }, []);

  useEffect(() => {
    if (active) {
      clearTimeout(tout);
      setTout(null);
    } else {
      const t = setTimeout(() => {
        props.logout();
      // }, (1000 * 60 * 5));
      }, (1000 * 60 * 25));
      setTout(t);
    }
  }, [active]);

  const token = localStorage.getItem('AUTH_TOKEN');

  const toutDialog = (
    <Dialog
      open={(token !== null && !active)}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Inactivity detected!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          It seams you&apos;re iddle. We&apos;re going to log you out in 5 minutes unless you do something (move mouse, etc.).
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  return (
    <AppStateProvider reducer={reducer} initialState={initialState}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={materialTheme}>
          <TokenTimeout
            logout={props.logout}
          />
          <Routes childProps={childProps} layout={layoutConfig} />
          <Idle
            timeout={1000 * 60 * 25}
            onChange={({ idle }) => {
              setActive(!idle);
            }}
          />
          {toutDialog}
          <ErrorInfoComponent />
          <ToastComponent />
          <ConfirmationInfo />
        </MuiThemeProvider>
      </JssProvider>
    </AppStateProvider>
  );
};

function mapStateToProps(state) {
  return {
    themeConfig: state.theme,
    layoutConfig: state.layout
  };
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

App.propTypes = {
  themeConfig: PropTypes.shape({
    contentTheme: PropTypes.shape({
      direction: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  layoutConfig: PropTypes.shape({}).isRequired
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
