import * as types from './types';

const initialState = {
  codeValidation: {
    isLoading: true,
    success: null,
    error: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.REQUEST_VERIFICATION_CODE_VALIDATION:
    return {
      ...state,
      codeValidation: {
        ...state.codeValidation,
        success: null,
        isLoading: true,
        error: null
      }
    };
  case types.REQUEST_VERIFICATION_CODE_VALIDATION_SUCCESS:
    return {
      ...state,
      codeValidation: {
        ...state.codeValidation,
        success: action.payload.message,
        isLoading: false,
        error: null
      }
    };
  case types.REQUEST_VERIFICATION_CODE_VALIDATION_FAILURE:
    return {
      ...state,
      codeValidation: {
        ...state.codeValidation,
        success: null,
        isLoading: false,
        error: action.payload.message
      }
    };
  default:
    return state;
  }
};

export default reducer;
