import { put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { getLoans } from './actions';
import { showConfirmationInfo } from '../app-state/actions';

function* afterStatusUpdate() {
  yield put(getLoans(localStorage.getItem('AUTH_CLIENT_ID'), localStorage.getItem('AUTH_ID')));
  yield put(showConfirmationInfo('Success!!'));
}

export default function* loans() {
  yield takeEvery(types.CANCEL_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
}
