/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getClientsUsers = clientId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getClientsUser = (clientId, userId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_USER_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const createClientsUsers = (clientId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      role: data.role,
      username: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      bio: data.bio,
      address: data.address,
      job_position: data.jobPosition,
      payroll_amount: parseFloat(data.payrollAmount),
      payment_schedule: data.paymentSchedule,
      allowance: parseFloat(data.allowance)
    })
  }
});

export const updateClientsUsers = (clientId, userId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/by-user`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      logo: data.logo,
      first_name: data.firstName,
      last_name: data.lastName,
      address: data.address,
      mmoney: {
        enabled: data.mmoneyEnabled,
        value: data.mmoneyAddress
      },
      mmoney_short_url: {
        enabled: data.mmoneyEnabled,
        value: data.mmoneyShortURL
      }
    })
  }
});

export const changePassword = (clientId, userId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CHANGE_PASSWORD_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/password`,
    method: 'PUT',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      old_password: data.oldPassword,
      new_password: data.password
    })
  }
});

export const getRequestPay = (clientId, userId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/request-pay`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const createRequestPay = (clientId, userId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENTS_USER_REQUEST_PAY_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/request-pay`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      amount: parseFloat(data.balance),
      issue_time: data.issueTime
    })
  }
});

export const simulateInstantPayroll = (clientId, userId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.SIMULATE_INSTANT_PAYROLL_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.SIMULATE_INSTANT_PAYROLL_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.SIMULATE_INSTANT_PAYROLL_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/simulate/instant-payroll`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      amount: parseFloat(data.balance),
      issue_time: data.issueTime
    })
  }
});

export const createFcmRegistration = (clientId, userId, token) => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_FCM_REGISTRATION_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_FCM_REGISTRATION_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_FCM_REGISTRATION_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/fcm-registrations`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      fcm_registration_token: token
    })
  }
});

export const updateNotificationsSettings = (clientId, userId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_NOTIFICATIONS_SETTINGS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${userId}/notifications`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify(data)
  }
});

export const resetRequest = () => ({
  type: types.RESET_CLIENTS_USER_REQUEST
});
