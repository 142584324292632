import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const tryToAuthenticate = () => ({
  type: types.TRY_TO_AUTHENTICATE,
  payload: {
    token: localStorage.getItem('AUTH_TOKEN'),
    username: localStorage.getItem('AUTH_USERNAME')
  }
});

export const login = (username, password) => ({
  [RSAA]: {
    types: [
      {
        type: types.LOGIN_REQUEST,
        payload: (action, state, res) => ({ username })
      },
      {
        type: types.LOGIN_REQUEST_SUCCESS,
        payload: (action, state, res) => res.json()
      },
      {
        type: types.LOGIN_REQUEST_FAILURE,
        payload: (action, state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/auth/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json'
    },
    body: JSON.stringify({
      username,
      password,
      role: 'user'
    })
  }
});

export const register = (username, password) => ({
  [RSAA]: {
    types: [
      {
        type: types.REGISTER_REQUEST,
        payload: (action, state, res) => ({ username })
      },
      {
        type: types.REGISTER_REQUEST_SUCCESS,
        payload: (action, state, res) => ({
          username,
          ...res.json()
        })
      },
      {
        type: types.REGISTER_REQUEST_FAILURE,
        payload: (action, state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/auth/signup`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  }
});

export const registerConfirm = (username, password, code) => ({
  [RSAA]: {
    types: [
      types.REGISTER_CONFIRM_REQUEST,
      {
        type: types.REGISTER_CONFIRM_REQUEST_SUCCESS,
        payload: (action, state, res) => res.json()
      },
      {
        type: types.REGISTER_CONFIRM_REQUEST_FAILURE,
        payload: (action, state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/auth/signup-confirm`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json'
    },
    body: JSON.stringify({
      username,
      password,
      confirmation_code: code
    })
  }
});

export const logout = () => ({
  type: types.LOGOUT
});

export const resetPassword = username => ({
  [RSAA]: {
    types: [
      {
        type: types.RESET_PASSWORD_REQUEST,
        payload: (action, state, res) => ({ username })
      },
      {
        type: types.RESET_PASSWORD_REQUEST_SUCCESS,
        payload: (action, state, res) => res.json()
      },
      {
        type: types.RESET_PASSWORD_REQUEST_FAILURE,
        payload: (action, state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/auth/forgot-password`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json'
    },
    body: JSON.stringify({
      username
    })
  }
});

export const resetPasswordConfirm = (username, password, code) => ({
  [RSAA]: {
    types: [
      types.RESET_PASSWORD_CONFIRM_REQUEST,
      {
        type: types.RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS,
        payload: (action, state, res) => res.json()
      },
      {
        type: types.RESET_PASSWORD_CONFIRM_REQUEST_FAILURE,
        payload: (action, state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/auth/forgot-password-confirm`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json'
    },
    body: JSON.stringify({
      username,
      password,
      confirmation_code: code
    })
  }
});
