import { connect } from 'react-redux';
import Component from './MenuSidenav.component';
import { getClientsUser } from '../../../../redux/clients-users/actions';
import { setSidenavOpen } from '../../../../actions/layout.actions';


const mapStateToProps = state => ({
  settings: state.clientsUsers.userSettings,
  user: state.clientsUsers.user,
  variant: state.layout.sidenavVariant
});

const mapDispatchToProps = dispatch => ({
  getClientsUser: (clientId, userId) => dispatch(getClientsUser(clientId, userId)),
  closeSidenav: () => dispatch(setSidenavOpen(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
