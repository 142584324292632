import * as types from './types';
import _ from 'underscore';
import moment from 'moment';

const initial = {
  loading: false,
  login_time: null,
  username: null,
  token: null,
  id: null,
  role: null,
  authenticated: false,
  message: null,
  confirmation: {
    loading: false,
    success: null,
    error: null
  }
};

const auth = (state = initial, action) => {
  switch (action.type) {
  case types.TRY_TO_AUTHENTICATE:
    const { token, username, id, role } = action.payload;

    if (!_.isEmpty(token) && !_.isEmpty(username)) {
      return {
        ...state,
        authenticated: true,
        token,
        username,
        id,
        role
      };
    }

    return { ...state };
  case types.LOGIN_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: null,
      login_time: null,
      loading: true
    };
  case types.LOGIN_REQUEST_SUCCESS:
    return {
      ...state,
      role: action.payload.data.role,
      token: action.payload.data.token,
      id: action.payload.data.id,
      authenticated: true,
      message: null,
      login_time: moment().format(),
      loading: false
    };
  case types.LOGIN_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      login_time: null,
      loading: false
    };
  case types.LOGOUT:
    return {
      ...state,
      username: null,
      authenticated: false,
      token: null,
      role: null,
      id: null
    };
  case types.REGISTER_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: null,
      loading: true
    };
  case types.REGISTER_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.REGISTER_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  case types.REGISTER_CONFIRM_REQUEST:
    return {
      ...state,
      confirmation: {
        ...state.confirmation,
        success: null,
        loading: true,
        error: null
      }
    };
  case types.REGISTER_CONFIRM_REQUEST_SUCCESS:
    return {
      ...state,
      confirmation: {
        ...state.confirmation,
        success: action.payload.message,
        loading: false,
        error: null
      }
    };
  case types.REGISTER_CONFIRM_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false,
      confirmation: {
        ...state.confirmation,
        success: null,
        loading: false,
        error: action.payload.message
      }
    };
  case types.RESET_PASSWORD_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: null,
      loading: true
    };
  case types.RESET_PASSWORD_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.RESET_PASSWORD_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST:
    return {
      ...state,
      message: null,
      loading: true
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      role: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  default:
    return state;
  }
};

export default auth;
